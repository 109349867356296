import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridValueGetterParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { ExamStatsViewModel } from "../../ApiHelper";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper/Paper";
import { Box, Breadcrumbs, Button, Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { TableDefinition } from "../../common/TableDefinition";
import MUIDataTable from "mui-datatables";

function ExamList(props: { exams: ExamStatsViewModel[], searchText: string }) {
  const navigate = useNavigate();

  function handleClick(id: number) {
    navigate("/app/recordings/" + id + "?searchText=" + encodeURIComponent(props.searchText));
  }


  const tableDefinition = new TableDefinition(props.exams)
    .addColumn("ID", row => row.exam.id, false)
    .addColumn("Navn", row => row.exam.name, true, 'textField')
    .addColumn("Dato", row => row.exam.start.toFormat("d/M"))
    .addColumn("Start", row => row.exam.start.toFormat("HH:mm"))
    .addColumn("Type", row => row.exam.type, true, 'textField')
    .addColumn("Uddannelse", row => row.exam.education, true, 'textField')
    .addColumn("Tilmeldte", row => row.examineeCount, true, null)

  return (
    <div className="table-cursor-pointer">
      <MUIDataTable
        title={"Prøver"}
        data={tableDefinition.rows.map(row => tableDefinition.columns.map(c => c.value(row)))}
        columns={tableDefinition.columns.map(c => { return { label: c.title, name: c.title, options: { display: c.display, filter: c.filterType !== null, filterType: c.filterType || 'multiselect' } } })}
        options={{
          print: false,
          download: false,
          pagination: false,
          selectableRows: 'none',
          searchAlwaysOpen: true,
          searchPlaceholder: "Søg efter prøvenavne",
          onRowClick: (row) => handleClick(parseInt(row[0])),
          onCellClick: () => { },
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <></>
            // <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} examId={props.exam.exam.id} rows={rows} onSaved={refresh} />
          )
        }}

      /></div>
  );
}

export default ExamList;
