import { FilterType } from "mui-datatables";
import { ReactFragment } from "react";

export class TableDefinition<T> {
    constructor(public rows: T[]) {
  
    }
  
    addColumn(title: string, value: (row: T) => string | null | undefined | ReactFragment | number, display: boolean = true, filterType: FilterType | null = 'multiselect') {
      this.columns.push(new ColumnDefinition<T>(title, value, display, display ? filterType : null));
  
      return this;
    }

    addFilterOnlyColumn(title: string, value: (row: T) => string, filterType: FilterType)
    {
        this.columns.push(new ColumnDefinition<T>(title, value, false, filterType));
        
        return this;
    }
  
    columns: ColumnDefinition<T>[] = [];
  }
  
 export class ColumnDefinition<T> {
    constructor(public title: string, public value: (row: T) => string | null | undefined | ReactFragment | number, public display: boolean, public filterType: FilterType | null) {
  
    }
  }