/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddRecordingModel {
  /** @format int32 */
  sequenceNumber?: number;
  /**
   * @format int32
   * @example 42
   */
  recordingId?: number;
  /** @format byte */
  bytes?: string;
  remoteStorageReference?: string;
  /** @format byte */
  iv?: string;
}

export interface AddScreenshotModel {
  /** @format byte */
  bytes?: string;
  failIfNoFaceDetected?: boolean;
}

export interface BearerToken {
  token?: string;
}

export interface Bookmark {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  session?: Session;
  comment?: string;
  /** @format int32 */
  positionInSeconds?: number;
  /** @format date-time */
  created?: string;
  /**
   * @format int32
   * @example 42
   */
  reviewerId?: number;
  reviewer?: Reviewer;
  /** @format date-time */
  modified?: string;
}

export interface BookmarkModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  reviewerId?: number;
  reviewerName?: string;
  comment?: string;
  /** @format int32 */
  positionInSeconds?: number;
  /** @format date-time */
  created?: string;
}

export interface Chat {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  recordingUser?: RecordingUser;
  /**
   * @format int32
   * @example 42
   */
  recordingUserId?: number;
  assignedToReviewer?: Reviewer;
  /**
   * @format int32
   * @example 42
   */
  assignedToReviewerId?: number | null;
  chatState?: ChatState;
  chatRole?: ChatRole;
  chatMessages?: ChatMessage[];
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
}

export interface ChatExamineeModel {
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number;
  /**
   * @format int32
   * @example 42
   */
  examId?: number;
  /** @format date-time */
  examStart?: string;
}

export interface ChatMessage {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  session?: Session;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number | null;
  reviewer?: Reviewer;
  /**
   * @format int32
   * @example 42
   */
  reviewerId?: number | null;
  chat?: Chat;
  /**
   * @format int32
   * @example 42
   */
  chatId?: number;
  sendByRecordingUser?: boolean;
  text?: string;
  /** @format date-time */
  sent?: string;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
}

export interface ChatMessageModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  text?: string;
  /** @format date-time */
  sent?: string;
  username?: string;
  name?: string;
  isEmployee?: boolean;
}

export interface ChatModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  recordingUserId?: number;
  username?: string;
  name?: string;
  allowStreaming?: boolean;
  chatState?: ChatState;
  chatRole?: ChatRole;
  /**
   * @format int32
   * @example 42
   */
  assignedToReviewerId?: number | null;
  assignedToReviewerName?: string | null;
  /** @format date-time */
  lastActivity?: string | null;
  networkTestResult?: NetworkTestResult;
  userExams?: ChatExamineeModel[];
}

/** @format int32 */
export enum ChatRole {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface ChatSetFieldsModel {
  chatState?: ChatState;
  chatRole?: ChatRole;
  /**
   * @format int32
   * @example 42
   */
  assignedToReviewerId?: number | null;
}

/** @format int32 */
export enum ChatState {
  Value0 = 0,
  Value1 = 1,
}

export interface ClientChatSettingsModel {
  introductionText?: string;
  enableChat?: boolean;
  signalRMethodName?: string;
}

export interface ClientSigninModel {
  token?: string;
  operatingSystem?: string;
  hostname?: string;
}

export interface Exam {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  tenantId?: number;
  tenant?: Tenant;
  externalId?: string;
  name?: string;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
  type?: string | null;
  education?: string | null;
  english?: boolean;
  defaultTitle?: string;
  examinees?: Examinee[];
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
}

export interface ExamModel {
  exam?: Exam;
  /** @format int32 */
  examineeCount?: number;
  defaultTitle?: string;
}

export interface ExamWithExamineesModel {
  exam?: Exam;
  examinees?: ExamineeRowModel[];
  defaultTitle?: string;
}

export interface Examinee {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  examId?: number;
  exam?: Exam;
  /**
   * @format int32
   * @example 42
   */
  recordingUserId?: number;
  recordingUser?: RecordingUser;
  /** @format date-time */
  end?: string;
  /** @format date-time */
  stopRecordingAt?: string;
  /** @format date-time */
  submittedAt?: string | null;
  reviewState?: ReviewState;
  reviewComments?: ExamineeReviewComment[];
  sessions?: Session[];
  streamReviewer?: Reviewer;
  /**
   * @format int32
   * @example 42
   */
  streamReviewerId?: number | null;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
  education?: string | null;
  goToExaminationLink?: string | null;
  isHidden?: boolean;
  chat?: boolean;
  webcam?: boolean;
  microphone?: boolean;
  screenRecording?: boolean;
  screenshotRecording?: boolean;
  idCard?: boolean;
  showIntroductionTab?: boolean;
  processRecording?: boolean;
}

export interface ExamineeFaceCompareModel {
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  examName?: string;
  username?: string;
  name?: string;
  automaticFaceCompareResult?: boolean | null;
  manualFaceCompareResult?: boolean | null;
  /**
   * @format int32
   * @example 42
   */
  recordingId?: number;
  /** @format date-time */
  recordingStart?: string;
}

export interface ExamineeModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  recordingUserId?: number;
  /** @format date-time */
  end?: string;
  /** @format date-time */
  submittedAt?: string | null;
  allowStreaming?: boolean;
  username?: string;
  name?: string;
  /**
   * @format int32
   * @example 42
   */
  examId?: number;
  examName?: string;
  examType?: string | null;
  examEducation?: string | null;
  report?: ExamineeReport;
  networkTestResult?: NetworkTestResult;
  reviewState?: ReviewStateModel;
  sessions?: SessionModel[];
}

export interface ExamineeReport {
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number;
  summary?: string;
  hasErrors?: boolean;
  gaps?: ExamineeReportGap[];
  sessions?: ExamineeReportSession[];
}

export interface ExamineeReportGap {
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
  /** @format date-span */
  duration?: string;
}

export interface ExamineeReportSession {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
}

export interface ExamineeReviewComment {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number;
  examinee?: Examinee;
  /**
   * @format int32
   * @example 42
   */
  reviewerId?: number;
  reviewer?: Reviewer;
  /** @format date-time */
  created?: string;
  comment?: string;
  /** @format date-time */
  modified?: string;
}

export interface ExamineeRowModel {
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number;
  /**
   * @format int32
   * @example 42
   */
  recordingUserId?: number;
  /** @format date-time */
  submittedAt?: string | null;
  networkTestResult?: NetworkTestResult;
  username?: string;
  name?: string;
  /** @format date-time */
  end?: string;
  report?: ExamineeReport;
  lastActivity?: LastActivityModel;
  /** @format int32 */
  bookmarkCount?: number;
  allowStreaming?: boolean;
  streamReviewer?: ReviewerModel;
  reviewState?: ReviewStateModel;
}

export interface FaceCompareSnapshot {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  session?: Session;
  /** @format date-time */
  created?: string;
  /** @format byte */
  bytes?: string;
  /** @format double */
  score?: number | null;
}

export interface FaceCompareSnapshotModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /** @format double */
  score?: number | null;
  /** @format byte */
  bytes?: string;
}

export interface File {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /** @format byte */
  sha256?: string;
  /** @format date-time */
  deleted?: string | null;
  /** @format date-time */
  created?: string;
  fileStore?: FileStore;
  /** @format int64 */
  size?: number;
  /**
   * @format int32
   * @example 42
   */
  fileStoreId?: number;
  /**
   * @format int32
   * @example 42
   */
  tenantId?: number;
  tenant?: Tenant;
}

export interface FileStore {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  path?: string;
  /** @format date-time */
  created?: string;
  active?: boolean;
  isArchive?: boolean;
  /** @format date-time */
  modified?: string;
}

export interface FullChatModel {
  chat?: ChatModel;
  messages?: ChatMessageModel[];
}

export type HeartbeatModel = object;

export interface HeartbeatResponse {
  allowRecording?: boolean;
  /** @format int32 */
  stopRecordingInSeconds?: number | null;
}

export interface IntroductionTextModel {
  introductionText?: string;
}

export interface LastActivityModel {
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number;
  /** @format date-time */
  end?: string;
  /** @format date-time */
  submittedAt?: string | null;
  /** @format date-time */
  lastSessionStart?: string | null;
  /** @format date-time */
  lastRecordingActivity?: string | null;
  hasRecordings?: boolean;
  hasLoggedIn?: boolean;
  /** @format int32 */
  lastActivityInMinutes?: number | null;
  /** @format int32 */
  lastActivityInMinutesRelativeToNowOrExamEnd?: number | null;
}

export interface LogItem {
  /** @format date-time */
  logged?: string;
  text?: string;
}

export interface LogModel {
  items?: LogItem[];
}

export interface LogResult {
  sessions?: LogSession[] | null;
}

export interface LogSession {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  created?: string;
  systemCheck?: boolean;
  /** @format date-time */
  systemCheckCompleted?: string | null;
  logText?: string | null;
  /** @format date-time */
  firstRecording?: string | null;
  /** @format date-time */
  lastRecording?: string | null;
}

/** @format int32 */
export enum MediaType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface MetricItem {
  /** @format date-time */
  logged?: string;
  key?: string;
  tags?: Record<string, string>;
  /** @format int32 */
  value?: number;
}

export interface MetricModel {
  items?: MetricItem[];
}

export interface NetworkTest {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  session?: Session;
  /** @format int32 */
  pingMilliseconds?: number;
  pingResult?: NetworkTestResult;
  /** @format int32 */
  downloadMilliseconds?: number;
  /** @format int32 */
  downloadBytes?: number;
  downloadResult?: NetworkTestResult;
  /** @format int32 */
  uploadMilliseconds?: number;
  /** @format int32 */
  uploadBytes?: number;
  uploadResult?: NetworkTestResult;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
}

export interface NetworkTestColorResult {
  result?: NetworkTestResult;
  type?: NetworkTestType;
}

export interface NetworkTestGroup {
  title?: string;
}

export interface NetworkTestModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  /** @format date-time */
  sessionCreated?: string;
  systemCheck?: boolean;
  /** @format date-time */
  systemCheckCompleted?: string | null;
  /** @format int32 */
  pingMilliseconds?: number;
  pingResult?: NetworkTestResult;
  /** @format int32 */
  downloadMilliseconds?: number;
  /** @format int32 */
  downloadBytes?: number;
  downloadResult?: NetworkTestResult;
  /** @format int32 */
  uploadMilliseconds?: number;
  /** @format int32 */
  uploadBytes?: number;
  uploadResult?: NetworkTestResult;
  /** @format date-time */
  created?: string;
  /** @format int32 */
  uploadKbitPerSecond?: number;
  /** @format int32 */
  downloadKbitPerSecond?: number;
}

export interface NetworkTestReport {
  results?: NetworkTestColorResult[];
  allowedToProceed?: boolean;
}

/** @format int32 */
export enum NetworkTestResult {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface NetworkTestSettings {
  enableNetworkTest?: boolean;
  /** @format int32 */
  uploadTestBytes?: number;
  testGroups?: NetworkTestGroup[];
}

/** @format int32 */
export enum NetworkTestType {
  Value0 = 0,
  Value1 = 1,
}

export interface Recording {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  session?: Session;
  recordingParts?: RecordingPart[];
  /** @format date-time */
  created?: string;
  mediaType?: MediaType;
  archiveSuccessful?: boolean | null;
  initialAnalyzeFinished?: boolean;
  /**
   * @format int32
   * @example 42
   */
  archiveFileId?: number | null;
  archiveFile?: File;
  /** @format date-time */
  archiveStarted?: string | null;
  /** @format date-time */
  modified?: string;
}

export interface RecordingModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  /** @format date-time */
  created?: string;
  /** @format int32 */
  offsetInMilliseconds?: number;
}

export interface RecordingPart {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /** @format int32 */
  sequenceNumber?: number;
  file?: File;
  /**
   * @format int32
   * @example 42
   */
  fileId?: number | null;
  /**
   * @format int32
   * @example 42
   */
  recordingId?: number;
  recording?: Recording;
  /** @format date-time */
  created?: string;
  remoteStorageReference?: string | null;
  /** @format date-time */
  remoteStorageFailed?: string | null;
  /** @format byte */
  iv?: string | null;
}

export interface RecordingPartModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /** @format int32 */
  sequenceNumber?: number;
  /** @format date-time */
  created?: string;
  /** @format int32 */
  relativeTimeInSeconds?: number;
}

export interface RecordingScreenshot {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  session?: Session;
  /** @format date-time */
  created?: string;
  /** @format byte */
  bytes?: string;
}

export interface RecordingUser {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  tenantId?: number;
  tenant?: Tenant;
  externalId?: string;
  username?: string | null;
  name?: string | null;
  examinees?: Examinee[];
  recordingUserSessions?: Session[];
  chat?: Chat;
  allowStreaming?: boolean;
  networkTestResult?: NetworkTestResult;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
}

export interface RecordingUserSearchResult {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  username?: string;
  name?: string;
}

export interface ResultModel {
  /** @format double */
  pingMs?: number;
  /** @format double */
  uploadMs?: number;
  /** @format double */
  downloadMs?: number;
}

export interface ReviewComment {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /** @format date-time */
  created?: string;
  comment?: string;
  reviewerName?: string;
}

/** @format int32 */
export enum ReviewState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface ReviewStateModel {
  state?: ReviewState;
  comment?: ReviewComment[];
  statusText?: string;
}

export interface Reviewer {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  username?: string;
  name?: string;
  /**
   * @format int32
   * @example 42
   */
  tenantId?: number;
  tenant?: Tenant;
  assignedChats?: Chat[];
  examineeReviewComments?: ExamineeReviewComment[];
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
}

export interface ReviewerModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  name?: string;
}

export interface RtcIceServer {
  urls?: string[];
  username?: string | null;
  password?: string | null;
}

export interface ScreenshotModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  /** @format date-time */
  created?: string;
  /** @format byte */
  bytes?: string;
}

export interface Session {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  recordingUserId?: number;
  recordingUser?: RecordingUser;
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number | null;
  examinee?: Examinee;
  recordings?: Recording[];
  streamingConnections?: StreamingConnection[];
  bookmarks?: Bookmark[];
  networkTests?: NetworkTest[];
  faceCompareSnapshots?: FaceCompareSnapshot[];
  recordingScreenshots?: RecordingScreenshot[];
  operatingSystem?: string | null;
  hostname?: string | null;
  systemCheck?: boolean;
  /** @format date-time */
  systemCheckCompleted?: string | null;
  automaticFaceCompareResult?: boolean | null;
  manualFaceCompareResult?: boolean | null;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
  /** @format date-time */
  lastActivity?: string | null;
  /** @format byte */
  encryptionKey?: string | null;
  sessionReference?: string | null;
  ip?: string | null;
  geoLocation?: string | null;
  isEnglish?: boolean | null;
}

export interface SessionModel {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  recordingUserId?: number;
  /**
   * @format int32
   * @example 42
   */
  examineeId?: number | null;
  /** @format date-time */
  created?: string;
  automaticFaceCompareResult?: boolean | null;
  manualFaceCompareResult?: boolean | null;
  /** @format date-time */
  firstRecordingStarted?: string;
  /** @format date-time */
  lastRecording?: string;
  geoLocation?: string | null;
  audioRecordings?: RecordingModel[];
  webcams?: RecordingModel[];
  screenCaptures?: RecordingModel[];
  screenshotRecordings?: RecordingModel[];
  screenshot?: ScreenshotModel;
}

export interface SetAssignedReviewersInputModel {
  assignedReviewers?: Record<string, number>;
}

export interface SetExamineeAllowStreamingInputModel {
  examineeIds?: number[];
  allowStreaming?: boolean;
}

export interface Settings {
  token?: string;
  onlySystemCheck?: boolean;
  viewWindowStartUri?: string | null;
  goToExaminationLink?: string | null;
  updateFeedUriWindows?: string;
  rtcIceServers?: RtcIceServer[];
  networkTestSettings?: NetworkTestSettings;
  uploadPartUri?: string;
  restrictionToilet?: boolean;
  restrictionEarphone?: boolean;
  restrictionAids?: boolean;
  showGoToTestButton?: boolean;
  guideTextAsJson?: string | null;
  textsPackageName?: string | null;
  encryptionKeyBase64?: string;
  languageIsEnglish?: boolean;
  azureSasContainer?: string | null;
  recordWebcam?: boolean;
  recordMicrophone?: boolean;
  recordScreen?: boolean;
  recordScreenshot?: boolean;
  recordProcesses?: boolean;
  idCard?: boolean;
  showIntroductionTab?: boolean;
  /** @format int32 */
  recorderTimeSliceInMilliseconds?: number;
  /** @format int32 */
  recorderWebcamFramerate?: number;
  /** @format int32 */
  recorderScreenFramerate?: number;
  /** @format int32 */
  screenshotRecordingIntervalInMilliseconds?: number;
  /** @format int32 */
  processRecordingIntervalInMilliseconds?: number;
  allowStreaming?: boolean;
  allowSaveRecordings?: boolean;
}

export interface StreamingConnection {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  /**
   * @format int32
   * @example 42
   */
  sessionId?: number;
  session?: Session;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  disconnected?: string | null;
  /** @format date-time */
  heartbeat?: string;
  connectionId?: string;
  /** @format date-time */
  modified?: string;
}

export interface Tenant {
  /**
   * @format int32
   * @example 42
   */
  id?: number;
  code?: string;
  validIssuer?: string;
  viewWindowStartUri?: string;
  hostname?: string;
  chatIntroductionText?: string | null;
  signingKeyHex?: string | null;
  /** @format byte */
  signingKey?: string | null;
  remoteStorageUri?: string | null;
  restrictionToilet?: boolean;
  restrictionEarphone?: boolean;
  restrictionAids?: boolean;
  showGoToTestButton?: boolean;
  textsPackageName?: string | null;
  azureBlobContainer?: string | null;
  azureBlobConnectionString?: string | null;
  enableChat?: boolean;
  enableLiveStreaming?: boolean;
  enableIdCard?: boolean;
}

export interface UploadDataModel {
  base64Data?: string;
}

export interface WebRtcConfiguration {
  rtcIceServers?: RtcIceServer[];
}

export interface WhoAmIModel {
  /**
   * @format int32
   * @example 42
   */
  reviewerId?: number;
  name?: string;
  enableChat?: boolean;
  enableLiveStreaming?: boolean;
  enableIdCard?: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) => (input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input),
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob ? property : typeof property === "object" && property !== null ? JSON.stringify(property) : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title Arcanic.ItxFlexRemote.Web
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name TokenCreate
     * @request POST:/api/token
     */
    tokenCreate: (data: ClientSigninModel, params: RequestParams = {}) =>
      this.request<Settings, any>({
        path: `/api/token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientChat
     * @name ClientchatList
     * @request GET:/api/clientchat
     */
    clientchatList: (params: RequestParams = {}) =>
      this.request<ChatMessageModel[], any>({
        path: `/api/clientchat`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientChat
     * @name ClientchatMessageSendCreate
     * @request POST:/api/clientchat/message/send
     */
    clientchatMessageSendCreate: (
      query?: {
        text?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/clientchat/message/send`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientChat
     * @name ClientchatSettingsList
     * @request GET:/api/clientchat/settings
     */
    clientchatSettingsList: (params: RequestParams = {}) =>
      this.request<ClientChatSettingsModel, any>({
        path: `/api/clientchat/settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientLog
     * @name ClientlogCreate
     * @request POST:/api/clientlog
     */
    clientlogCreate: (data: LogModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/clientlog`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientMetric
     * @name ClientmetricCreate
     * @request POST:/api/clientmetric
     */
    clientmetricCreate: (data: MetricModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/clientmetric`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Heartbeat
     * @name HeartbeatCreate
     * @request POST:/api/heartbeat
     */
    heartbeatCreate: (data: HeartbeatModel, params: RequestParams = {}) =>
      this.request<HeartbeatResponse, any>({
        path: `/api/heartbeat`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NetworkTest
     * @name NetworktestDownloadList
     * @request GET:/api/networktest/download
     */
    networktestDownloadList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/networktest/download`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NetworkTest
     * @name NetworktestUploadCreate
     * @request POST:/api/networktest/upload
     */
    networktestUploadCreate: (data: UploadDataModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/networktest/upload`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NetworkTest
     * @name NetworktestPingCreate
     * @request POST:/api/networktest/ping
     */
    networktestPingCreate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/networktest/ping`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NetworkTest
     * @name NetworktestResultCreate
     * @request POST:/api/networktest/result
     */
    networktestResultCreate: (data: ResultModel, params: RequestParams = {}) =>
      this.request<NetworkTestReport, any>({
        path: `/api/networktest/result`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recording
     * @name RecordingPartRemoteCreate
     * @request POST:/api/recording/part/remote
     */
    recordingPartRemoteCreate: (
      query?: {
        /** @format int32 */
        SequenceNumber?: number;
        /**
         * @format int32
         * @example 42
         */
        RecordingId?: number;
        RemoteStorageReference?: string;
        /** @format byte */
        Iv?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/recording/part/remote`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recording
     * @name RecordingPartCreate
     * @request POST:/api/recording/part
     */
    recordingPartCreate: (data: AddRecordingModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/recording/part`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recording
     * @name RecordingCreate
     * @request POST:/api/recording
     */
    recordingCreate: (
      query?: {
        mediaType?: MediaType;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/recording`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recording
     * @name SystemcheckCompletedCreate
     * @request POST:/api/systemcheck/completed
     */
    systemcheckCompletedCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/systemcheck/completed`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recording
     * @name ScreenshotCreate
     * @request POST:/api/screenshot
     */
    screenshotCreate: (data: AddScreenshotModel, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/screenshot`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Test
     * @name RecordingParttestCreate
     * @request POST:/api/recording/parttest
     */
    recordingParttestCreate: (data: AddRecordingModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/recording/parttest`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  chat = {
    /**
     * No description
     *
     * @tags Chat
     * @name ChatList
     * @request GET:/chat
     */
    chatList: (params: RequestParams = {}) =>
      this.request<ChatModel[], any>({
        path: `/chat`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name RecordinguserDetail
     * @request GET:/chat/recordinguser/{recordingUserId}
     */
    recordinguserDetail: (recordingUserId: number, params: RequestParams = {}) =>
      this.request<FullChatModel, any>({
        path: `/chat/recordinguser/${recordingUserId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name RecordinguserSearchList
     * @request GET:/chat/recordinguser/search
     */
    recordinguserSearchList: (
      query?: {
        searchText?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecordingUserSearchResult[], any>({
        path: `/chat/recordinguser/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name ChatDetail
     * @request GET:/chat/{id}
     */
    chatDetail: (id: number, params: RequestParams = {}) =>
      this.request<FullChatModel, any>({
        path: `/chat/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name MessageCreate
     * @request POST:/chat/{chatId}/message
     */
    messageCreate: (
      chatId: number,
      query?: {
        text?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/chat/${chatId}/message`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name FieldCreate
     * @request POST:/chat/{chatId}/field
     */
    fieldCreate: (chatId: number, data: ChatSetFieldsModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/chat/${chatId}/field`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name IntroductiontextUpdate
     * @request PUT:/chat/introductiontext
     */
    introductiontextUpdate: (
      query?: {
        introductionText?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/chat/introductiontext`,
        method: "PUT",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name IntroductiontextList
     * @request GET:/chat/introductiontext
     */
    introductiontextList: (params: RequestParams = {}) =>
      this.request<IntroductionTextModel, any>({
        path: `/chat/introductiontext`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  exam = {
    /**
     * No description
     *
     * @tags Exam
     * @name ExamList
     * @request GET:/exam
     */
    examList: (
      query?: {
        searchText?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ExamModel[], any>({
        path: `/exam`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Exam
     * @name ExamDetail
     * @request GET:/exam/{examId}
     */
    examDetail: (examId: number, params: RequestParams = {}) =>
      this.request<ExamWithExamineesModel, any>({
        path: `/exam/${examId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Exam
     * @name ExamineeAllowstreamingUpdate
     * @request PUT:/exam/{examId}/examinee/allowstreaming
     */
    examineeAllowstreamingUpdate: (examId: number, data: SetExamineeAllowStreamingInputModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/exam/${examId}/examinee/allowstreaming`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Exam
     * @name ReviewerSetassignedUpdate
     * @request PUT:/exam/{examId}/reviewer/setassigned
     */
    reviewerSetassignedUpdate: (examId: number, data: SetAssignedReviewersInputModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/exam/${examId}/reviewer/setassigned`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  examinee = {
    /**
     * No description
     *
     * @tags Exam
     * @name SetReviewUpdate
     * @request PUT:/examinee/{examineeId}/setReview
     */
    setReviewUpdate: (
      examineeId: number,
      query?: {
        reviewState?: ReviewState;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/examinee/${examineeId}/setReview`,
        method: "PUT",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Exam
     * @name AddCommentCreate
     * @request POST:/examinee/{examineeId}/addComment
     */
    addCommentCreate: (
      examineeId: number,
      query?: {
        comment?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/examinee/${examineeId}/addComment`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Exam
     * @name ExamineeDetail
     * @request GET:/examinee/{examineeId}
     */
    examineeDetail: (examineeId: number, params: RequestParams = {}) =>
      this.request<ExamineeModel, any>({
        path: `/examinee/${examineeId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  session = {
    /**
     * No description
     *
     * @tags Exam
     * @name SessionDetail
     * @request GET:/session/{sessionId}
     */
    sessionDetail: (sessionId: number, params: RequestParams = {}) =>
      this.request<SessionModel, any>({
        path: `/session/${sessionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FaceCompare
     * @name FacecompareTodayList
     * @request GET:/session/facecompare/today
     */
    facecompareTodayList: (params: RequestParams = {}) =>
      this.request<ExamineeFaceCompareModel[], any>({
        path: `/session/facecompare/today`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FaceCompare
     * @name FacecompareSnapshotsDetail
     * @request GET:/session/facecompare/snapshots/{sessionId}
     */
    facecompareSnapshotsDetail: (sessionId: number, params: RequestParams = {}) =>
      this.request<FaceCompareSnapshotModel[], any>({
        path: `/session/facecompare/snapshots/${sessionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FaceCompare
     * @name FacecompareScoresnapshotsCreate
     * @request POST:/session/facecompare/scoresnapshots/{sessionId}
     */
    facecompareScoresnapshotsCreate: (sessionId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/session/facecompare/scoresnapshots/${sessionId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FaceCompare
     * @name FacecompareManualresultCreate
     * @request POST:/session/facecompare/manualresult/{sessionId}/{result}
     */
    facecompareManualresultCreate: (sessionId: number, result: boolean, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/session/facecompare/manualresult/${sessionId}/${result}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FaceCompare
     * @name FacecompareManualresultResetCreate
     * @request POST:/session/facecompare/manualresult/{sessionId}/reset
     */
    facecompareManualresultResetCreate: (sessionId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/session/facecompare/manualresult/${sessionId}/reset`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RecordingBookmark
     * @name BookmarkCreate
     * @request POST:/session/{SessionId}/bookmark
     */
    bookmarkCreate: (
      sessionId: number,
      query?: {
        comment?: string;
        /** @format int32 */
        positionInSeconds?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/session/${sessionId}/bookmark`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RecordingBookmark
     * @name BookmarkDetail
     * @request GET:/session/{SessionId}/bookmark
     */
    bookmarkDetail: (sessionId: number, params: RequestParams = {}) =>
      this.request<BookmarkModel[], any>({
        path: `/session/${sessionId}/bookmark`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RecordingBookmark
     * @name BookmarkFromStreamCreate
     * @request POST:/session/{SessionId}/bookmarkFromStream
     */
    bookmarkFromStreamCreate: (
      sessionId: number,
      query?: {
        comment?: string;
        /** @format date-time */
        timestamp?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/session/${sessionId}/bookmarkFromStream`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name IdcardDetail
     * @request GET:/session/{sessionId}/idcard
     */
    idcardDetail: (sessionId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/session/${sessionId}/idcard`,
        method: "GET",
        ...params,
      }),
  };
  export = {
    /**
     * No description
     *
     * @tags ExamExport
     * @name ExamList
     * @request GET:/export/exam
     */
    examList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/export/exam`,
        method: "GET",
        ...params,
      }),
  };
  log = {
    /**
     * No description
     *
     * @tags Log
     * @name ExamineeDetail
     * @request GET:/log/examinee/{examineeId}
     */
    examineeDetail: (examineeId: number, params: RequestParams = {}) =>
      this.request<LogResult, any>({
        path: `/log/examinee/${examineeId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  recordinguser = {
    /**
     * No description
     *
     * @tags NetworkTest
     * @name NetworktestDetail
     * @request GET:/recordinguser/{recordingUserId}/networktest
     */
    networktestDetail: (recordingUserId: number, params: RequestParams = {}) =>
      this.request<NetworkTestModel[], any>({
        path: `/recordinguser/${recordingUserId}/networktest`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  recording = {
    /**
     * No description
     *
     * @tags Recording
     * @name PartDetail
     * @request GET:/recording/{id}/part
     */
    partDetail: (id: number, params: RequestParams = {}) =>
      this.request<RecordingPartModel[], any>({
        path: `/recording/${id}/part`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  reviewer = {
    /**
     * No description
     *
     * @tags Reviewer
     * @name ReviewerList
     * @request GET:/reviewer
     */
    reviewerList: (params: RequestParams = {}) =>
      this.request<ReviewerModel[], any>({
        path: `/reviewer`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  streaming = {
    /**
     * No description
     *
     * @tags Streaming
     * @name WebrtcconfigurationList
     * @request GET:/streaming/webrtcconfiguration
     */
    webrtcconfigurationList: (params: RequestParams = {}) =>
      this.request<WebRtcConfiguration, any>({
        path: `/streaming/webrtcconfiguration`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  time = {
    /**
     * No description
     *
     * @tags Time
     * @name TimeList
     * @request GET:/time
     */
    timeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/time`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  viewwindow = {
    /**
     * No description
     *
     * @tags ViewWindow
     * @name TokenList
     * @request GET:/viewwindow/token
     */
    tokenList: (params: RequestParams = {}) =>
      this.request<BearerToken, any>({
        path: `/viewwindow/token`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  whoami = {
    /**
     * No description
     *
     * @tags WhoAmI
     * @name WhoamiList
     * @request GET:/whoami
     */
    whoamiList: (params: RequestParams = {}) =>
      this.request<WhoAmIModel, any>({
        path: `/whoami`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
