import { Alert, Breadcrumbs, Button, Chip, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridValueGetterParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { ReactFragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ExamineeRowModel, ExamWithExamineesModel } from "../Api";
import { ApiClient, ExamineeRowViewModel, ExamStatsViewModel, ExamWithExamineesViewModel } from "../ApiHelper";
import { ReviewStateView } from "./ReviewStateEdit";
import NetworkTestResultView from "./NetworkTestResultView";
import AppContext from "../AppContext";
import MUIDataTable from "mui-datatables";
import { TableDefinition } from "../common/TableDefinition";

function RecordingExam() {
  const [exam, setExam] = useState<null | ExamWithExamineesViewModel>(null);

  let { id, searchText } = useParams();

  useEffect(() => {
    (async () => {
      const exam = await ApiClient.getExam(parseInt(id || "0"));

      setExam(exam);
    })();
  }, []);

  if (exam === null) {
    return <CircularProgress />;
  }

  return <ExamDetails exam={exam} />;
}


function ExamDetails(props: { exam: ExamWithExamineesViewModel }) {
  const myContext = useContext(AppContext);

  const navigate = useNavigate();

  const handleClick = (id: any) => {
    navigate("/app/recordings/examinee/" + id);
  };

  const [exam, setExam] = useState(props.exam);

  let rows = exam.examinees.map(x => x); // copy

  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  if (!rows) {
    return <CircularProgress />;
  }

  const refresh = async () => {
    setExam(await ApiClient.getExam(exam.exam.id));
  };

  const onSelectChange = (id: number, value: boolean) => {
    setSelectedValues((v) => {
      if (value) {
        var copy = [...v];

        copy.push(id);

        return copy;
      } else {
        return v.filter((c) => c !== id);
      }
    });
  };

  const onToggleAll = (id: number, value: boolean) => {
    if (value) {
      setSelectedValues(rows.map((r) => r.examineeId));
    } else {
      {
        setSelectedValues([]);
      }
    }
  };

  if (!myContext) return <></>;

  console.log(rows.length);

  const tableDefinition = new TableDefinition(rows)
    .addColumn("Examinee ID", row => row.examineeId, false)
    .addColumn("Brugernavn", row => row.username, true, 'textField')
    .addColumn("Navn", row => row.name, true, 'textField')
    .addColumn("Afleveret", row => row.submittedAt?.toFormat("HH:mm"), true, null)
    .addFilterOnlyColumn("Afleveret", row => row.submittedAt ? "Har afleveret" : "Ikke afleveret", 'dropdown')
    .addColumn("Netværkstest", row => <><NetworkTestResultView recordingUserId={row.recordingUserId} value={row.networkTestResult} /></>, true, null)
    .addColumn("Tillad live proctoring", row => row.allowStreaming ? "Ja" : "Nej", myContext.enableLiveStreaming)
    .addColumn("Live proctoring tilsyn", row => row.allowStreaming ? (row.streamReviewer ? row.streamReviewer.name : "(ingen)") : "", myContext.enableLiveStreaming)
    .addColumn("Optagelsesstatus", row => <>

      {row.lastActivity.hasLoggedIn && row.report.hasErrors && <>
        <Alert severity="error">{row.report.summary}</Alert>
      </>
      }


      {!(row.lastActivity.hasLoggedIn && row.report.hasErrors) && <>
        <LastActivity
          hasRecordings={row.lastActivity.hasRecordings!}
          hasLoggedIn={row.lastActivity.hasLoggedIn!}
          lastActivityInMinutes={row.lastActivity.lastActivityInMinutes!}
          lastActivityInMinutesRelativeToNowOrExamEnd={row.lastActivity.lastActivityInMinutesRelativeToNowOrExamEnd!}
        />
      </>
      }
    </>, true, null)
    .addColumn("Status", row => <ReviewStateView reviewState={row.reviewState} />, true, null)
    .addFilterOnlyColumn("Status", row => row.reviewState.statusText!, 'multiselect')
    .addColumn("Kommentarer", row => row.reviewState.comment?.length || 0, true, null)
    .addFilterOnlyColumn("Kommentarer", row => row.reviewState.comment?.length ? "Har kommentarer" : "Ingen kommentarer", 'dropdown')
    .addColumn("Bogmærker", row => row.bookmarkCount, true, null)
    .addFilterOnlyColumn("Bogmærker", row => row.bookmarkCount ? "Har bogmærker" : "Ingen bogmærker", 'dropdown');


  return (
    <>


      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/app/recordings">
          Optagelser
        </Link>

        <Link underline="hover" color="inherit" href={"/app/recordings/" + exam.exam.id}>
          {props.exam.exam.name}
        </Link>
      </Breadcrumbs>

      <RightActions examId={exam.exam.id} />

      <div className="table-cursor-pointer">
        <MUIDataTable
          title={"Studerende"}
          data={tableDefinition.rows.map(row => tableDefinition.columns.map(c => c.value(row)))}
          columns={tableDefinition.columns.map(c => { return { label: c.title, name: c.title, options: { display: c.display, filter: c.filterType !== null, filterType: c.filterType || 'textField' } } })}
          options={{
            print: false,
            download: false,
            pagination: false,
            selectableRows: myContext.enableLiveStreaming ? 'multiple' : 'none',
            searchAlwaysOpen: true,
            searchPlaceholder: "Søg efter tilmeldinger",
            textLabels: {
              body: {
                noMatch: "Sorry, no matching records found",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
              },
              pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
              },
              toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
              }
            },
            onRowClick: (row) => handleClick(row[0]),
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
              <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} examId={props.exam.exam.id} rows={rows} onSaved={refresh} />
            )
          }}
        />
      </div>
    </>
  );
}

function LastActivity(props: { hasRecordings: boolean, hasLoggedIn: boolean, lastActivityInMinutes: number | null, lastActivityInMinutesRelativeToNowOrExamEnd: number | null }) {
  if (!props.hasLoggedIn) {
    return <Chip label="Ikke logget ind" size="small" color="error" />;
  }

  if (!props.hasRecordings) {
    return <Chip label="Intet data" size="small" color="error" />;
  }

  if (props.lastActivityInMinutesRelativeToNowOrExamEnd === null) {
    return <Chip label="Ikke logget ind" size="small" color="error" />;
  }

  let minutter = props.lastActivityInMinutesRelativeToNowOrExamEnd + " min";

  if (props.lastActivityInMinutesRelativeToNowOrExamEnd === 0) {
    minutter = "OK";
  }

  if (props.lastActivityInMinutesRelativeToNowOrExamEnd < 3) {
    return <Chip label={minutter} size="small" color="success" />;
  } else if (props.lastActivityInMinutesRelativeToNowOrExamEnd < 5) {
    return <Chip label={minutter} size="small" color="warning" />;
  } else {
    return <Chip label={minutter} size="small" color="error" />;
  }
}

function CustomToolbarSelect(props: { selectedRows: any, displayData: any, setSelectedRows: any, examId: number, onSaved: () => void, rows: ExamineeRowViewModel[] }) {
  if (!props?.displayData) {
    return <></>;
  }

  const selectedRows: ExamineeRowModel[] = props.selectedRows.data.map((x: any) => props.rows[x.dataIndex]);

  return <ExamineeActions examId={props.examId} onSaved={props.onSaved} selectedExamineeIds={selectedRows.map(row => row.examineeId!)} />
}

function RightActions(props: { examId: number }) {
  const myContext = useContext(AppContext);
  const navigate = useNavigate();

  if (!myContext?.enableLiveStreaming) {
    return <></>;
  }

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: "1" }}>

        </div>

        <div>

          <Button variant="contained" onClick={() => navigate(`/app/exam/${props.examId}/reviewers/distribute`)}>
            Fordel deltagere på tilsyn
          </Button>
        </div>
      </div>
    </Paper>
  );
}

function ExamineeActions(props: { examId: number, selectedExamineeIds: number[], onSaved: () => void }) {
  const myContext = useContext(AppContext);

  let disabled = props.selectedExamineeIds.length === 0;

  const setLiveProctoring = async (value: boolean) => {
    await ApiClient.examineeAllowstreaming(props.examId, props.selectedExamineeIds, value);
    props.onSaved();
  };

  if (!myContext?.enableLiveStreaming) {
    return <></>;
  }

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: "1" }}>
          <Button variant="contained" disabled={disabled} onClick={() => setLiveProctoring(true)} sx={{ mr: 1 }}>
            Slå live proctoring til
          </Button>
          <Button variant="contained" disabled={disabled} onClick={() => setLiveProctoring(false)} sx={{ mr: 1 }}>
            Slå live proctoring fra
          </Button>
        </div>
      </div>
    </Paper>
  );
}

function SelectIdBox(props: { id: number; initialValue: boolean; onChange: (id: number, value: boolean) => void }) {
  const onChange = () => {
    props.onChange(props.id, !props.initialValue);
  };

  return <input type="checkbox" checked={props.initialValue} onChange={onChange} />;
}

export default RecordingExam;
