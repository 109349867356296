import { COMMENT_KEYS } from "@babel/types";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip/Chip";
import { useState } from "react";
import { ReviewComment, ReviewStateModel } from "../Api";
import { ApiClient, ReviewCommentViewModel } from "../ApiHelper";

export function ReviewStateView(props: {
  reviewState: ReviewStateModel;
}) {
  const [reviewState, setReviewState] = useState<ReviewStateOptionViewModel>(
    new ReviewStateOptionViewModel(props.reviewState.statusText!, props.reviewState.state || 0)
  );

  const comments = props.reviewState.comment || [];

  const commentsText = comments
    .map((c) => c.reviewerName + ": " + c.comment)
    .join("\n\n");

  return (
    <>
      <span title={commentsText}>{reviewState.formatted}</span>
    </>
  );
}

export function ReviewStateEdit(props: {
  examineeId: number;
  reviewState: ReviewStateModel | null;
}) {
  const [status, setStatus] = useState<number>(props.reviewState?.state || 0);
  const [saving, setSaving] = useState(false);
  const [reviewState, setReviewState] = useState(props.reviewState);

  async function statusChanged(event: SelectChangeEvent<number>) {
    var value = typeof event.target.value === "number" ? event.target.value : 0;
    setStatus(value);

    const tmpValue: any = value;

    await saveWrapper(
      ApiClient.examineeSetReviewUpdate(
        props.examineeId,
        tmpValue
      )
    );

    window.location.reload();
  }

  async function saveWrapper(callback: Promise<any>) {
    setSaving(true);

    await callback;

    await new Promise(resolve => setTimeout(resolve, 200))

    setSaving(false);
  }

  return (
    <>
      <Grid container={true}>
      <FormControl sx={{ m: 1 }}>
        <InputLabel>Status</InputLabel>
        <Select
            size="small" value={status} label="Status" onChange={statusChanged} disabled={saving}>
          <MenuItem value={0}>Ikke vurderet</MenuItem>
          <MenuItem value={1}>Ingen optagelser</MenuItem>
          <MenuItem value={2}>Skal vurderes af anden</MenuItem>
          <MenuItem value={3}>OK</MenuItem>
          <MenuItem value={4}>Snyd</MenuItem>
        </Select>
      </FormControl>
      <ReviewCommentAdd examineeId={props.examineeId} />
      </Grid>

      <ReviewCommentList comments={props.reviewState?.comment?.map(c => new ReviewCommentViewModel(c)) || []} />
    </>
  );
}

export function ReviewCommentList(props: { comments: ReviewCommentViewModel[] }) {
  if (!props.comments) {
    return <></>;
  }

  return (
    <>
      <Paper sx={{mx: 1}} elevation={4}>
          <table>

      {props.comments.map((c) => {
        return (
            
          <tr key={c.id}>
            <td style={{paddingRight: '10px'}}>{c.created?.toFormat(" d/M kl. HH:mm:ss")}</td>
            <td style={{paddingRight: '10px'}}>{c.reviewerName}</td>
            <td style={{ whiteSpace: "pre-line" }}>{c.comment}</td>
          </tr>
        );
      })}
          </table>
          </Paper>

    </>
  );
}

export function ReviewCommentAdd(props: {
  examineeId: number;
}) {
  const [text, setText] = useState<string>("");
  const [saving, setSaving] = useState(false);

  async function saveWrapper(callback: Promise<any>) {
    setSaving(true);

    await callback;

    window.location.reload();
  }

  function save(event: any) {
      saveWrapper(ApiClient.examineeAddCommentCreate(
        props.examineeId,
        text
      ));
  }

  return (
    <>
      <form onSubmit={save}>
        <FormControl sx={{ m: 1, mb: 0 }}>
          <TextField 
            label="Skriv kommentar til vurderingen..."
            multiline
            value={text}
            size="small"
            
            onChange={(event) => setText(event.target.value)}
            sx={{ width: "30em" }}
          />
        </FormControl>
        
          <Button  sx={{ mt: 1, mb: 0 }}
           variant="contained" disabled={saving || !text} onClick={save}>
            Gem
          </Button>

      </form>
    </>
  );
}

class ReviewStateOptionViewModel {
  formatted: JSX.Element;
  constructor(name: string, private reviewState: 0 | 1 | 2 | 3 | 4) {
    this.formatted = this.getFormatted(name, reviewState);
  }

  getFormatted(name: string, reviewState: 0 | 1 | 2 | 3 | 4) {
    switch (reviewState) {
      case 0:
        return (
          <Chip
            label={name}
            sx={{ backgroundColor: "#a19f8c", color: "#F3F3F3" }}
            size="small"
          />
        );
      case 1:
        return (
          <Chip
            label={name}
            sx={{ backgroundColor: "#000000", color: "#F3F3F3" }}
            size="small"
          />
        );
      case 2:
        return (
          <Chip
            label={name}
            sx={{ backgroundColor: "#f5e642", color: "#F3F3F3" }}
            size="small"
          />
        );
      case 3:
        return (
          <Chip
            label={name}
            sx={{ backgroundColor: "#009900", color: "#F3F3F3" }}
            size="small"
          />
        );
      case 4:
        return (
          <Chip
            label={name}
            sx={{ backgroundColor: "#990000", color: "#F3F3F3" }}
            size="small"
          />
        );
      default:
        throw name + " er ikke en kendt reviewState";
    }
  }
}
